import React from "react"
import styles from "./Odds.module.css"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import Typography from "@material-ui/core/Typography"
import { useMediaQuery } from "@material-ui/core"
import useTheme from "@material-ui/core/styles/useTheme"
import odds from "../odds"


const Odds = ({ count, max, extraCount, extraMax, game, numberOfTickets }) => {
  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up("md"))
  return <>
    <Typography variant="h4" component="h2">Odds for {game}</Typography>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell colSpan={mdUp ? 2 : 1}>Matching numbers</TableCell>
          <TableCell align="right">Odds</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          [...Array(count + 1).keys()].reverse().map((correct) =>
            [...Array(extraCount + 1).keys()].reverse().flatMap((extraCorrect) =>
              (<TableRow key={`${correct}_${extraCorrect}`}>
                <TableCell>{correct}{extraCount > 0 && `+${extraCorrect}`}</TableCell>
                {
                  mdUp && <TableCell>
                    {[...Array(correct)].map((_, i) => <span className={styles.number}
                                                             key={`correct_${i}`}>&nbsp;</span>)}
                    {[...Array(extraCorrect)].map((_, i) => <span className={styles.extraNumber}
                                                                  key={`correct_extra_${i}`}>&nbsp;</span>)}
                  </TableCell>

                }
                <TableCell>
                  <Typography align="right" noWrap={true}>
                    1 in {new Intl.NumberFormat().format(
                      odds(max, count, correct) * odds(extraMax, extraCount, extraCorrect) / numberOfTickets)
                    }
                  </Typography>
                </TableCell>
              </TableRow>),
            ),
          )
        }
      </TableBody>
    </Table>
  </>
}
export default Odds
