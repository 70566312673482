import React from "react"
import { graphql, Link } from "gatsby"
import OddsCalculator from "../components/tools/OddsCalculator"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FAQItem from "../components/FAQItem"

const LotteryOddsCalculator = ({ data, location }) =>
  <Layout location={location} title={data.site.siteMetadata.title}>
    <SEO title="Lottery Odds Calculator - Multiple Tickets, Custom Games"
         description="Want to know the chances of winning lottery games like Powerball or Mega Millions? Look no further, this is the tool for you." />
    <h1>Lottery Odds Calculator - Multiple Tickets, Custom Games</h1>
    <p>Want to know the chances of winning lottery games like Powerball or Mega Millions? Look no further, this is the tool for you.</p>
    <p><strong>
      Lottolibrary's lottery odds calculator can help you check the odds of winning the jackpot or smaller prizes of <a
      href="https://powerball.com" target="_blank" rel="noreferrer">Powerball</a>, <a
      href="https://www.megamillions.com" target="_blank" rel="noreferrer">Mega Millions</a>, Pick3, Pick4, <a
      href="https://www.euro-millions.com/" target="_blank" rel="noreferrer">EuroMillions</a>, or any other popular
      lottery game.
    </strong></p>
    <OddsCalculator />
    <h2>FAQ</h2>
    <section itemScope itemType={"https://schema.org/FAQPage"}>
      <FAQItem question="Which Lottery Game Has The Best Odds?">
          If you are looking for a comprehensive comparision of lottery games, you should definitely check out our <Link to="/best-odds/">Lottery Odds Comparision Tool</Link>. It has an extensive database of the winning odds of all the lottery games in the world.
      </FAQItem>
      <FAQItem question="For What Games Can the Lottery Odds Calculator Display the Odds?">
        <strong>Using this calculator you can get the odds for any lottery game.
          We've included presets for the most popular games like <a href="https://powerball.com" target="_blank"
                                                                    rel="noreferrer">Powerball</a>, <a
            href="https://www.megamillions.com"
            target="_blank" rel="noreferrer">Mega
            Millions</a>, Pick3, Pick4, Hot Lotto, <a href="https://www.euro-millions.com/" target="_blank"
                                                      rel="noreferrer">
            Euromillions</a>, <a href="https://www.luckyforlife.us/" target="_blank" rel="noreferrer"> Lucky for
            Life</a> and <a
            href="https://www.national-lottery.co.uk/games/thunderball" target="_blank"
            rel="noreferrer">Thunderball</a>.</strong><br />
        You are not limited to these presets though: by choosing the <code>Custom Game</code> option you can tweak the
        parameters and see how the odds change or check the odds of any theoritical game.
      </FAQItem>
      <FAQItem question="How Much Does It Cost to Use the Lottery Odds Calculator? Is It a Free Tool?">
        <strong><Link to="/">LottoLibrary.com</Link>'s lottery odds calculator tool is free to
          use.</strong> There are no hidden fees or charges.<br />
        <i>Please note: We might show you 3<sup>rd</sup> party ads or affiliate links. You can read more about this in
          our <Link to="/terms-and-conditions/">T&amp;C document</Link>.</i>
      </FAQItem>
      <FAQItem question="How Are Lottery Odds Calculated?">
        <strong>
          Lottolibrary's lottery odds calculator tool relies on a branch of mathematics called <a
          href="https://en.wikipedia.org/wiki/Probability_theory" target="_blank" rel="noreferrer">probability
          theory</a>.</strong><br />
        In a fair game of lottery, each number has an equal chance to be drawn, which means that lotteries follow the
        so-called <a
        href="https://en.wikipedia.org/wiki/Discrete_uniform_distribution" target="_blank" rel="noreferrer">discrete
        uniform
        distribution</a>.
        The exact odds of winning a certain prize can be calculated by dividing the number of winning combinations by
        the
        number of all possible combinations. These two numbers can be easily computed using some of the basic formulas
        of <a href="https://en.wikipedia.org/wiki/Combinatorics" target="_blank" rel="noreferrer">combinatorics</a>.
      </FAQItem>
    </section>
    <h2>Disclaimer</h2>
    <section>
      This tool is intended for informational, educational, and entertainment purposes only.
    </section>
    <h2>Similar Tools</h2>
    <section>
      <ul>
        <li>
          <Link to="/lottery-number-generator/">Lottery Number Generator</Link>
        </li>
        <li>
          <Link to="/lottery-tax-calculator/">Lottery Tax Calculator</Link>
        </li>
        <li>
          <Link to="/lottery-annuity-calculator/">Lottery Annuity Payout Calculator</Link>
        </li>
        <li>
          <Link to="/best-odds/">Lottery Odds Comparison</Link>
        </li>
        <li>
          <Link to="/expected-value-calculator/">Expected Value Calculator</Link>
        </li>
      </ul>
    </section>
    <h2>Related Articles</h2>
    <section>
      <ul>
        <li>
          <Link to="/can-you-predict-the-lottery/">Can You Predict The Lottery?</Link>
        </li>
        <li>
          <Link to="/are-lotteries-rigged/">Are Lotteries Rigged?</Link>
        </li>
      </ul>
    </section>
    <h2>Sources</h2>
    <section>
      <ul>
        <li>
          <a href="https://en.wikipedia.org/wiki/Probability_theory" target="_blank" rel="noreferrer">Probability theory</a>
        </li>
        <li>
          <a
            href="https://en.wikipedia.org/wiki/Discrete_uniform_distribution"
            target="_blank" rel="noreferrer">Discrete uniform distribution</a>
        </li>
        <li>
          <a href="https://en.wikipedia.org/wiki/Combinatorics" target="_blank" rel="noreferrer">Combinatorics</a>
        </li>
      </ul>
    </section>
  </Layout>

export default LotteryOddsCalculator

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
