import React, { useRef, useState } from "react"
import GameOptions from "../GameOptions"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import Odds from "./Odds"
import Paper from "@material-ui/core/Paper/Paper"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"


const OddsCalculator = ({ gameOptions }) => {
  const [options, setOptions] = useState({ display: false })
  const [displayOdds, setDisplayOdds] = useState(false)
  const [numberOfTickets, setNumberOfTickets] = useState(1)
  const settingsRef = useRef(null)

  const updateOptions = () => {
    setOptions({
      rnd: Math.random(),
      ...gameOptions,
      numberOfTickets,
    })
  }

  const generateNumbers = () => {
    updateOptions()
    window.scrollTo(0, settingsRef.current.offsetTop)
    setDisplayOdds(true)
  }

  return (
    <Paper elevation={1}>
      <Box p={4}>
        <Typography ref={settingsRef} variant="h4" component="h2">Lottery Odds Calculator</Typography>
        <Box p={2}>
          <GameOptions />
        </Box>
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Number of tickets to buy"
                type="number"
                min={1}
                defaultValue={numberOfTickets}
                onChange={(e) => setNumberOfTickets(parseInt(e.target.value))}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Button onClick={generateNumbers} variant="contained" color="primary">Show The Odds!</Button>
            </Grid>
          </Grid>
        </Box>
        {displayOdds &&
          <Odds {...options} />
        }
      </Box>
    </Paper>
  )

}
const mapStateToProps = ({ gameOptions }) => ({ gameOptions })

OddsCalculator.propTypes = {
  gameOptions: PropTypes.shape(
    {
      count: PropTypes.number,
      max: PropTypes.number,
      extraCount: PropTypes.number,
      extraMax: PropTypes.number,
      unique: PropTypes.bool,
    },
  ),
}
export default connect(mapStateToProps)(OddsCalculator)
