const Combinatorics = require("js-combinatorics")

const memoize = func => {
  const cache = {}
  return (...params) => {
    const key = btoa(JSON.stringify({ func, params }))
    if (!(key in cache)) {
      cache[key] = func(...params)
    }
    return cache[key]
  }
}

const combination = memoize(Combinatorics.C)

const odds = memoize((max, count, correct) =>
  combination(max, count) / combination(count, correct) / combination(max - count, count - correct),
)

export default odds
